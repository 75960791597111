import {ContentSection} from '../types/content-section.types'
import {parseRichTextContent} from './rich-text.serde'
import {validateDatoCmsTypes} from './common.serde'
import {ContentSectionFieldsFragment} from '../../../../__generated__/datocms.types'

export function parseContentSection(k: ContentSectionFieldsFragment): ContentSection {
  const section = validateDatoCmsTypes(k)

  return {
    __type: 'ContentSection',
    id: section.id,
    content: parseRichTextContent(section),
    title: section.title,
    plot: section.plot,
  }
}
